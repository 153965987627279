import { Controller } from "stimulus"

export default class extends Controller {

  countCharacters() {
    let count = event.target.value.length
    if (count <= 200) {
      this.changeCountColor()
      this.changeCountSpan(count)
    } 
    if (count == 200) {
      this.changeCountColor('red')
    }
  }

  changeCountSpan(count) {
    document.getElementById('picksheet-character-length').innerHTML = count
  }

  changeCountColor(color = '') {
    document.getElementById('picksheet-text-limit').style.color = color
  }

  updateComment() {
    Rails.ajax({
      type: 'POST',
      url: '/orders/update_comment',
      data: `id=${document.getElementById('order_id').value}&comment=${document.getElementById('order_picksheet_comment').value}`,
      success: (data) => {
        renderGenericFlash('success', 'Comment has been updated')
      },
      error: (data) => {
        renderGenericFlash('error', 'An error has occurred. Comment did NOT update.')
      }
    })
  }

}