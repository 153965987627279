// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)

application.load(definitionsFromContext(context))


window.intializeCart = (orderId) => {
  if (orderId) {
    Rails.ajax({
      type: 'GET',
      url: '/orders/remote_add_to_cart',
      dataType: 'script',
      data: `order_id=${orderId}`
    })
  } else {
    renderGenericModal('Error', 'An error has occurred while adding to the cart. Please note, you can NOT have more than 9999 quantity variant of a product in the cart.')    
  }
}