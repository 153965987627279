document.addEventListener('turbolinks:load', () => {
  $('[data-tooltip="tooltip"]').tooltip()
  $('[data-tooltip="popover"]').tooltip()
  
  getBrowser()
  fadeOutFlashMsg()
  initPagyNavBar()
})

initPagyNavBar = () => {
  let el = document.getElementsByClassName('pagy-combo-input')[0]
  if (el) {
    el.getElementsByTagName('input')[0].addEventListener('change', () => {
      window.location = `${window.location.pathname}?page=${event.currentTarget.value}`
    })
  }
}
