window.selectAgent = (klass, idArray) => {
  klass.addEventListener('change', () => {
    if (idArray.includes(klass.dataset.agentId)) {
      for (var i = 0; i < idArray.length; i++) {
        if (idArray[i] === klass.getAttribute('data-agent-id')) {
          idArray.splice(i, 1);
          i--;
        }
      }
    } else {
      idArray.push(klass.getAttribute('data-agent-id'))
    }
    document.getElementById('user_agent_ids_list').value = idArray
  })
}

document.addEventListener('turbolinks:load', () => {
  if (document.getElementById('user_agent_ids_list')) {
    let idArray = document.getElementById('user_agent_ids_list')
    if (idArray.value.split('') == '') {
      idArray = idArray.value.split('')
    } else {
      idArray = idArray.value.split(',')
    }
    let klasses = document.getElementsByClassName('agent-checkbox')
    for (var klass of klasses) {
      selectAgent(klass, idArray)
    }
  }
})