const browsers = {
  Chrome: 'Chromium',
  Firefox: 'Firefox',
  MSIE: 'Internet Explorer',
  Edge: 'Edge',
  Safari: 'Safari',
  Opera: 'Opera',
  Others: 'Others'
}

window.getBrowser = () => {
  try {
    for (let [key, value] of Object.entries(browsers)) {
      if (navigator.userAgent.indexOf(key) != -1) {
        return setBrowser(value)
      } else {
        setBrowser('Others')
      }
    }
  }
  catch (e) {
    console.log(`Unable to identify browser: ${e}`)
  }
}

setBrowser = (browserType) => {
  let el = document.getElementById('browser-check')
  if (el.dataset.browser === '') {
    el.dataset.browser = browserType
  }
}