import { Controller } from "stimulus"

export default class extends Controller {

  countRefCharacters() { 
    let count = event.target.value.length
    if (count <= 20) {
      this.changeRefCountColor()
      this.changeRefCountSpan(count)
    } 
    if (count == 20) {
      this.changeRefCountColor('red')
    }
  }

  duplicateRefApiEndpoint() {
    let orderRef = document.getElementById('order_order_ref')
    const order_id = document.getElementById('order_id')
    if (orderRef.value.trim().length > 0) {
      Rails.ajax({
        type: 'GET',
        url: '/orders/check_duplicate_ref',
        dataType: 'JSON',
        data: `order_ref_id=${orderRef.value}&order_id=${order_id.value}`,
        success: (data) => {
          orderRef.value = data.order_ref
          if (data.msg) {
            renderGenericFlash('error', data.msg )
          }
          if (orderRef.value.length <= 20) {
            this.changeRefCountSpan(orderRef.value.trim().length)
            this.changeRefCountColor('')
          }
        }
      })
    } else {
      orderRef.value = ''
      this.changeRefCountSpan(orderRef.value.trim().length)
      this.changeRefCountColor('')
    }
  }

  changeRefCountSpan(count) {
    document.getElementById('ref-character-length').innerHTML = count
  }

  changeRefCountColor(color = '') {
    document.getElementById('ref-text-limit').style.color = color
  }

  updateRef() {
    if (confirm('Are you sure?')){
      let order_ref = document.getElementById('order_order_ref').value
      Rails.ajax({
        type: 'POST',
        url: '/orders/update_order_ref',
        dataType: 'JSON',
        data: `order_id=${document.getElementById('order_id').value}&order_ref=${order_ref}`,
        success: () => {
          renderGenericFlash('success', 'Order Ref has been updated')
        },
        error: () => {
          renderGenericFlash('error', 'An error has occurred. Order Ref did NOT update.')
        }
      })
    }
  }

  addressPayload() {
    return JSON.stringify({
      name_1: document.getElementById("order_name_1").value,
      name_2: document.getElementById("order_name_2").value,
      address_1: document.getElementById("order_address_1").value,
      address_2: document.getElementById("order_address_2").value,
      city: document.getElementById("order_city").value,
      state: document.getElementById("order_state").value,
      zip: document.getElementById("order_zip").value,
    })
  }

  updateAddress() {
    if (confirm('Are you sure?')){
      Rails.ajax({
        type: 'POST',
        url: '/orders/update_order_address',
        dataType: 'JSON',
        data: `order_id=${document.getElementById('order_id').value}&address_payload=${this.addressPayload()}`,
        success: () => {
          renderGenericFlash('success', 'Address has been updated')
        },
        error: () => {
          renderGenericFlash('error', 'An error has occurred. Address did NOT update.')
        }
      })
    }
  }

}