import { Controller } from "stimulus"

export default class extends Controller {

  submitOrder() {
    if (confirm('You can not go back to edit this order after submission.  Are you sure you want to submit this order?')) {
      Rails.ajax({
        type: 'POST',
        url: `/orders/submit`,
        dataType: 'JSON',
        data: `id=${document.getElementById('order_id').value}&comment=${document.getElementById('order_picksheet_comment').value}`,
        success: (data) => { 
          if (document.getElementById("order-address-controller")) {
            this.updateAddress()
          }
          this.buildCartObject()
          this.renderCartMessage('Order Submitted Successfully! Redirect ...')
        },
        error: () => {
          renderGenericFlash('error', 'An error has occurred. Order was NOT submitted.')
        }
      })
    }
  }

  requiredAddressFieldsPresent() {
    const requiredAddressInput = document.getElementById('order-address-controller').querySelectorAll('input[required], select')
    const viewCartBtn = document.getElementById('view-cart-btn-hidden')
    const submitBtn = document.getElementById('order-submit-btn')
    this.checkForAddressError(requiredAddressInput)

    if (document.querySelectorAll('.has-error-border').length > 0) {
      this.scrollToError()
      renderGenericFlash('error', 'Please fill out the missing required information.')
      viewCartBtn.removeAttribute('data-toggle')
      submitBtn.removeAttribute('data-toggle')
      event.preventDefault()
    } else {
      viewCartBtn.setAttribute('data-toggle', 'modal')
      submitBtn.setAttribute('data-toggle', 'modal')
      return true
    }
  }

  checkForAddressError(requireElements) {
    requireElements.forEach(input => {
      if (input.value.trim().length < 1) {
        input.classList.add('has-error-border')
      } else {
        input.classList.remove('has-error-border')
      }
    })
  }

  scrollToError() {
    const error = document.querySelector('.has-error-border')
    if (error) {
      error.scrollIntoView({block: 'center', behavior: 'smooth'})
    }
  }

  checkAddress() {
    let address_el =  document.getElementById("order-address-controller")
    if (address_el == null) {
      return true
    }

    if (address_el) {
      return this.requiredAddressFieldsPresent()
    }
    return false
  }

  preCartAction() {
    this.removeSubmitButtonFromModal()
    if (this.checkAddress()){
      if (document.getElementById('shippingDropdown')){
        document.getElementById('shippingDropdown').classList.add('hidden')
      }
      if (this.data.get("submission")) {
        if (document.getElementById('shippingDropdown')){
          document.getElementById('shippingDropdown').classList.remove('hidden')
        }
        let modalFooter = document.getElementById('shoppingCartFooter')
        let button = '<div class="btn btn-primary" id= "sbmt_btn" data-action="click->cart#submitOrder">Finalize &amp; Submit Order</div>'
        if (modalFooter) {
          modalFooter.insertAdjacentHTML('beforeend', button)
        }
      }
    }
  }

  removeSubmitButtonFromModal() {
    let submitBtn = document.getElementById('sbmt_btn')
    if (submitBtn) {
      submitBtn.remove()
    }
  }

  changed() {
    let orderId = event.currentTarget.closest('tr').id
    this.mergeOrderIds(orderId)
  }

  validateQty() {
    let current_qty = event.currentTarget.getAttribute("data-cart-quantity")
    let changed_qty = event.currentTarget.value
    if (changed_qty == parseInt(changed_qty)) {
      if (parseInt(changed_qty) > 0) {
        return
      }
    }
    event.currentTarget.value = current_qty
  }

  removeDetail() {
    if (confirm('Are you sure you want to remove this product from the cart?')) {
      let orderId = event.currentTarget.closest('tr').id
      let cartObject = {'id': orderId}
      event.currentTarget.closest('tr').remove()
      this.updateCart(cartObject, 'remove_detail')
      this.checkForDetailRows()
    }
  }

  checkForDetailRows() {
    if (document.getElementsByClassName('detail-row').length === 0) {
      hideViewCartBtn()
      hideSubmitBtn()
      hidePickSheetComment()
      document.getElementsByClassName('close-modal')[0].click()
    }
  }

  updateCart(cartObject, endPoint = 'update_cart') {
    let json_payload = `payload=${JSON.stringify(cartObject)}`
    Rails.ajax({
      type: 'POST',
      url: `/order_details/${endPoint}`,
      dataType: 'JSON',
      data: json_payload,
      success: (data) => { 
        this.renderCartMessage('Cart has been updated!')
        this.updateSubTotal()
        this.updateShippingCost() // THIS FUNCTION UPDATES THE GRAND TOTAL ON SUCCESS
      }
    })
  }

  updateSubTotal() {
    Array.prototype.forEach.call(document.getElementsByClassName('dynamic_cost'), (cost, index) => {
      let subtotal = document.getElementsByClassName('detail-quantity')[index].value * cost.getAttribute('data-cart-dynamic-cost')
      document.getElementsByClassName('dynamic_subtotal')[index].innerHTML = currencyFormat(subtotal)
    })
  }

  updateShippingCost() {
    Rails.ajax({
      type: 'GET',
      url: `/orders/get_shipping_cost`,
      dataType: 'JSON',
      data: `id=${document.getElementById('order_id').value}`,
      success: (data) => { 
        if (document.getElementById('shipping_price')) {
          document.getElementById('shipping_price').innerHTML = currencyFormat(data.grand_total)
        }
        this.updateGrandTotal()
      }
    })
  }

  updateGrandTotal() {
    Rails.ajax({
      type: 'GET',
      url: `/orders/get_grand_total`,
      dataType: 'JSON',
      data: `id=${document.getElementById('order_id').value}`,
      success: (data) => { 
        document.getElementById('grand_total_price').innerText = currencyFormat(data.grand_total)
      }
    })
  }

  renderCartMessage(msg) {
    let updatMsgEl = document.getElementById('update-message')
    if (updatMsgEl) {
      updatMsgEl.innerText = msg
      setTimeout(() => {updatMsgEl.innerText = ''}, 3000) 
    }
  }

  mergeOrderIds(orderId) {
    let ids = this.data.get('ids')
    if (ids) {
      if (ids.indexOf(orderId) === -1) { 
        this.data.set('ids', this.data.get('ids').concat(`,${orderId}`)) 
      }
    } else {
      this.data.set('ids', orderId)
    }
  }

  clearMergedIds() {
    this.data.set('ids', '')
  }

  buildCartObject() {
    if (this.data.get('ids')) {
      let cartObject = {}
      let orderIdsArray = this.data.get('ids').split(',')

      orderIdsArray.forEach( (id, index) => {
        try {
          let el          = document.getElementById(`${id}`)
          // let item_info   = el.getElementsByClassName('detail-item-info')[0].value
          let quantity    = el.getElementsByClassName('detail-quantity')[0].value
          if (el.getElementsByClassName('dynamic_cost')[0]) {
            let subtotal    = el.getElementsByClassName('dynamic_cost')[0].getAttribute('data-cart-dynamic-cost')
          }


          cartObject[index] = {}
          cartObject[index]['id'] = id
          // cartObject[index]['item_info'] = item_info
          cartObject[index]['quantity'] = quantity
        }
        catch (err) {
          console.log('--> Skipped Previously Deleted Product')
        }
      })

      this.updateCart(cartObject)
      this.clearMergedIds()

    } else {
      this.renderCartMessage('Nothing to update!')
    }

  }

  // updateCarrierMethod() {
  //   let button = event.currentTarget
  //   Rails.ajax({
  //     type: 'POST',
  //     url: `/orders/update_carrier_method`,
  //     dataType: 'JSON',
  //     data: `id=${document.getElementById('order_id').value}&carrier_id=${event.currentTarget.getAttribute('data-carrier-id')}`,
  //     success: (data) => { 
  //       document.getElementById('shippingDropdown').getElementsByClassName('btn')[0].innerText = button.innerText.replace(/\r?\n|\r/,"")
  //       this.renderCartMessage('Shipping Method Successfully Updated ...')
  //     }
  //   })
  // }

  addressPayload() {
    return JSON.stringify({
      name_1: document.getElementById("order_name_1").value,
      name_2: document.getElementById("order_name_2").value,
      address_1: document.getElementById("order_address_1").value,
      address_2: document.getElementById("order_address_2").value,
      city: document.getElementById("order_city").value,
      state: document.getElementById("order_state").value,
      zip: document.getElementById("order_zip").value,
    })
  }

  updateAddress() {
    Rails.ajax({
      type: 'POST',
      url: '/orders/update_order_address',
      dataType: 'JSON',
      data: `order_id=${document.getElementById('order_id').value}&address_payload=${this.addressPayload()}`,
      success: () => {
        renderGenericFlash('success', 'Address has been updated')
      },
      error: () => {
        renderGenericFlash('error', 'An error has occurred. Address did NOT update.')
      }
    })
  }

}
