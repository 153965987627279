import { Controller } from "stimulus"

export default class extends Controller {

  createUser(event) {
    let klasses = document.getElementsByClassName('agent-checkbox')
    for (let i = 0; i < klasses.length; i++) { 
      if (klasses[i].checked) { return true }
    }
    renderGenericModal('Error', 'Please select at least one agent.')
    console.error('Invalid agent selection')
    event.preventDefault()
  }

}