import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "supplierDropdown", "productDropdown" ]

  emptyProductSelect() {
    this.productDropdownTarget.options[0].selected = true
  }

  getSupplierId() {
    return String(this.supplierDropdownTarget.value)
  }

  getProductsJson() {
    return `supplier_id=${this.getSupplierId()}`
  }

  allSupplierSelected(supplier_name) {
    return supplier_name == null
  }

  productMatchesSupplier(supplier_name, supplier_label) {
    return supplier_name == supplier_label
  }

  hideOrShowProducts(supplier_name) {
    let optgroups = this.productDropdownTarget.getElementsByTagName('optgroup')
    for (var i = 0; i < optgroups.length; i++) {
      let supplier_label = optgroups[i].getAttribute('label')
      if (this.allSupplierSelected(supplier_name) || this.productMatchesSupplier(supplier_name, supplier_label)) {
        optgroups[i].classList.remove('hidden') 
      } else {
        optgroups[i].classList.add('hidden')
      }
    }
  }

  fetchProducts() {
    Rails.ajax({
      type: 'GET',
      url: '/orders/find_products',
      dataType: 'JSON',
      data: this.getProductsJson(),
      success: (data) => {
        this.hideOrShowProducts(data.supplier_name)
      }
    })
  }
  
}
