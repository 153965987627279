import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "productOptions" ]

  qtyIncrement() {
    let qty = document.getElementById('contacts-eye-quantity').value
    if (qty == parseInt(qty)) {
      if (parseInt(qty) < 9999) {
        document.getElementById('contacts-eye-quantity').value = parseInt(qty) + 1
        enableAddToCartBtn()
      } else {
        renderGenericModal('Error', 'Quantity must less than 100.')
      }
    }
  }

  qtyDecrement() {
    let qty = document.getElementById('contacts-eye-quantity').value
    if (qty == parseInt(qty)) {
      if (parseInt(qty) > 1) {
        document.getElementById('contacts-eye-quantity').value = parseInt(qty) - 1
      } else {
        renderGenericModal('Error', 'Quantity must be greater than zero.')
      }
    }
  }

  resetSelection() {
    document.getElementById('order_product_id').selectedIndex = "0"
    hideParams('#contacts-eye-options')
    hideQuantity()
    hideAddToCartBtn()
  }

  validateProduct() {
    let el = document.getElementById("contacts-eye-options")
    if (el) { el.validProductOptions({productId: this.productId}) }
  }

  get productId() {
    return this.productOptionsTarget.value.split("-")[1]
  }

  setContactsVariant(value) {
    this.data.set("contacts-variant", value)
  }

  setContactsOrderId(value) {
    this.data.set("contacts-order-id", value)
  }

  qtyGreaterThanZero() {
    let qty = document.getElementById("contacts-eye-quantity").value
    return parseInt(qty) > 0
  }

  getContactsVariant(params_length) {
    let _variant = ''
    for (let i = 0; i < params_length; i++) {
      _variant += document.getElementById(`${i}-contacts`).value
    }
    return _variant
  }

  variantIsPresent() {
    return this.data.get("contacts-variant") !== null
  }

  buildOrderObject() {
    return { 
      contacts: { 
        // item_info: document.getElementById("order_item_info").value,
        order_ref: document.getElementById("order_order_ref").value,
        product_id: getSelectedValue(document.getElementById(`order_product_id`)).split("-")[1], 
        variant: this.data.get("contacts-variant"),
        quantity: document.getElementById("contacts-eye-quantity").value,
        name_1: document.getElementById("order_name_1").value.replace("&","%26"),
        name_2: document.getElementById("order_name_2").value.replace("&","%26"),
        address_1: document.getElementById("order_address_1").value,
        address_2: document.getElementById("order_address_2").value,
        city: document.getElementById("order_city").value,
        state: document.getElementById("order_state").value,
        zip: document.getElementById("order_zip").value
      } 
    }
  }

  buildOrderObjectNoDirectShip() {
    return { 
      contacts: { 
        order_ref: document.getElementById("order_order_ref").value,
        product_id: getSelectedValue(document.getElementById(`order_product_id`)).split("-")[1], 
        variant: this.data.get("contacts-variant"),
        quantity: document.getElementById("contacts-eye-quantity").value
      } 
    }
  }

  addToCart(e) {
    e.preventDefault()
    let params_length = document.getElementById("contacts-eye-options").getElementsByClassName("params").length


    if (params_length) {
      let orderDataJson = {}
      this.setContactsVariant(this.getContactsVariant(params_length))

      if (this.variantIsPresent()) {

        let address_el =  document.getElementById("order-address-controller")
        if (typeof(address_el) != 'undefined' && address_el != null)
        {
          orderDataJson = this.buildOrderObject()
        } else {
          orderDataJson = this.buildOrderObjectNoDirectShip()
        }

      } else {
        renderGenericModal('Error', 'An error has occurred with the sku, please try refreshing the page.')
      }

      if (!this.qtyGreaterThanZero()) {
        disableAddToCartBtn()
        return renderGenericModal('Error', 'Quantity must be greater than zero.')
      }

      this.addVariantToCart(orderDataJson)
    } else {
      renderGenericModal('Error', 'Select a product before adding to the cart.')
    }

  }

  addVariantToCart(orderDataJson) {
    let orderIdElement = document.getElementById('order_id')
    let browserElement = document.getElementById('browser-check')
    let json_payload = `payload=${ JSON.stringify(orderDataJson) }&order_id=${ orderIdElement.value }&browser=${ browserElement.dataset.browser }`
    Rails.ajax({
      type: 'POST',
      url: '/orders/add_to_cart',
      dataType: 'JSON',
      data: json_payload,
      success: (data) => {
        this.setContactsOrderId(data.order_id)
        this.checkAndAssignOrderIdValue(orderIdElement, data.order_id)
        this.changeWebIdOrderHeader(data.order_id)
        intializeCart(data.order_id)
        showViewCartBtn()
        showSubmitBtn()
        showOrderRefBtn()
        showOrderAddressBtn()
        showDeleteOrderBtn()
        showPickSheetComment()
        renderGenericFlash('success', 'Product has been added to the cart.')
      },
      error: (data) => renderGenericModal('Error', 'An error has occurred while adding to the cart. Please note, you can NOT have more than 9999 quantity variant of a product in the cart.')
    })
  }

  softDelete() {
    if (confirm('Are you sure?')) {
      Rails.ajax({
        type: 'POST',
        url: '/orders/delete',
        data: `id=${document.getElementById('order_id').value}`
      })
    }
  }

  checkAndAssignOrderIdValue(orderIdElement, data_id) {
    if (orderIdElement) {
      orderIdElement.value = data_id
    }
  }

  changeWebIdOrderHeader(orderId) {
    document.getElementById('web-id-div').innerHTML = orderId
  }

}