var _ = require('lodash/core')
var self = ''

HTMLDivElement.prototype.validProductOptions = function(options) {
  appendOneQty()
  
  const settings = $.extend({
    productId: null,
    variant: null
  }, options)

  if (settings.productId !== null) {
      return $(this).each( () => {
        self = $(this)
        options = {}
        const data = $.ajax({
          type: 'GET',
          url: '/products/'+settings.productId
        })
        data.then( (data) => {
          ({
            options
          } = data)
          const {
            headings
          } = data
          renderHeaders(headings)
          removeClasses()
          removeHandlers()
          if (isPresent(self.data('variant'), x => x.toString().length)) {
            return renderOptionsFromVariant(self.data('variant'))
          } else {
            return renderOptions(0, null)
          }
        })
  
        window.removeHandlers = () => $("#contacts-eye-options .options").off('change')
  
        window.removeClasses = function() {
          self.closest('.table-row').find('.quantity').removeClass('hidden') 
          return self.removeClass('valid')
        }
  
        window.renderHeaders = function(headings) {
          self.find('.params').remove()
          $(`#${self.data('input-id')}`).val('')
          let headerString = ""
          headings.forEach( (heading, index) => {
            headerString+= "<div class='table-cell auto params'>"
            headerString+= `<label for='${index}-contacts'>${heading}</label>`
            headerString+= `<select id='${index}-contacts' class='options form-control option-${index}'></select>`
            return headerString+= "</div>"
          })
          return self.find('.fields').prepend(headerString)  
        }
  
        window.renderOptions = function(index, nextKey = null) {
          const key = ((index === 0) && 'A') || nextKey
          if (key) { 
            return generateOptions((_.filter(options, {key} )), index, null)
          }
        }
  
        window.renderOptionsFromVariant = variant => parseVariant(variant, true)
  
        window.parseVariant = function(variant, generate, validate) {
          let index
          if (generate == null) { generate = false }
          if (validate == null) { validate = false }
          if (generate) { index = 0 }
          let key = 'A'
          variant = variant.toString()
          let valid = variant.length > 0
          while (variant.length > 0) {
            const row = _.first(options.filter( {key} ))
            const value = variant.substring(0, row.key_length)
            variant = variant.substring(row.key_length)
            const nextRowKeyLength = isPresent(_.first(options.filter( {key: row.next_key} )), x => x.key_length) || 0
            const validRecord = options.findWhere( {key, key_value: value} )
            if (validRecord != null) {
              key = validRecord.next_key
              if (generate) {
                generateOptions(options.filter({key: validRecord.key}), index, validRecord.key_value.toString())
                index++
              }
            } else {
              valid = false
              break
            }
          }
          if (validate) {
            return self.toggleClass('valid', valid)
          }
        }
  
        window.validateVariant = function() {
          const variant = $(`#${self.data('input-id')}`).val()
          return parseVariant(variant, false, true)
        }
  
        window.removeErrors = function(item) {
          $(item).removeClass('has-error')
          return $(item).find('.help-block').remove()
        }
  
        window.setVariant = function() {
          const variantInput = $("#contacts-eye-variant.hidden")
          variantInput.val('')
          let variantString = ''
          return $("#contacts-eye-options .options").each( (i, item) => {
            if (isPresent($(item).val(), x => x.length)) { variantString += $(item).val() }
            return variantInput.val(variantString)
          })
        }
  
        window.generateOptions = function(options, index, value = null) {
          const select = $(`#${index}-contacts`)
          select.html('')
          if (select.closest('.params').find('label').text() === 'Power') {
            options = _.sortBy( options, o => o.key_value / 100 )
          }
          let optionString = ((options.length > 1) && '<option></option>') || ''
          options.forEach( (option) => {
            if (option.key_value === "-0000") {
              const val_without_dash = option.display_value.replace("-", "")
              return optionString+= `<option value='${option.key_value}' data-next-key='${option.next_key}' class='plano'>${val_without_dash}</option>`
            } else {
              return optionString+= `<option value='${option.key_value}' data-next-key='${option.next_key}'>${option.display_value}</option>`
            }
          })
          select.append(optionString)
          if (options.length !== 1) {
            select.val(value)
          }
  
          $(`#${index}-contacts`).trigger('change')
          showQuantity()
          showAddToCartBtn()
          return $(`#${index}-contacts option[class='plano']`).prop('selected', true).trigger('change')
        }
      })
  } else {
    hideQuantity()
    hideAddToCartBtn()
    hideDeleteOrderBtn()
    hidePickSheetComment()
    hideParams(this)
  }
}

$(document).on('change', '#contacts-eye-options .options', function(e) {
  appendOneQty()
  $(this).removeClass('valid')
  if (isPresent($(this).val(), x => x.length)) { removeErrors($(this).closest('.params')) }
  setVariant()
  if (!$(this).closest('.table-cell').next().hasClass('quantity')) {
    $(this).closest('.table-cell').nextAll().find('select').empty()
    const index = parseInt($(this).attr('id')) + 1
    const nextKey = $('option:selected', this).data('next-key')
    return renderOptions(index, nextKey)
  } else {
    return validateVariant()
  }
})

window.appendOneQty = () => $(".prod-qty").find('.quantity').val('1')
window.hideParams = (el) => $(el).find('.params').remove()
window.hideQuantity = () => $(".prod-qty").addClass('hidden')
window.showQuantity = () => $(".prod-qty").removeClass('hidden')
window.hideAddToCartBtn = () => $('#add-to-cart-btn').addClass('hidden')
window.showAddToCartBtn = () => $('#add-to-cart-btn').removeClass('hidden')
window.showViewCartBtn = () => $('#view-cart-btn-hidden').removeClass('hidden')
window.hideViewCartBtn = () => $('#view-cart-btn-hidden').addClass('hidden')
window.showDeleteOrderBtn = () => $('#delete-order-btn-hidden').removeClass('hidden')
window.hideDeleteOrderBtn = () => $('#delete-order-btn-hidden').addClass('hidden')
window.showSubmitBtn = () => $('#order-submit-btn').removeClass('hidden')
window.hideSubmitBtn = () => $('#order-submit-btn').addClass('hidden')
window.enableAddToCartBtn = () => $('#add-to-cart-btn-disable').removeClass('disabled')
window.disableAddToCartBtn = () => $('#add-to-cart-btn-disable').addClass('disabled')
window.showPickSheetComment = () => $('#picksheet-comment-div').removeClass('hidden')
window.hidePickSheetComment = () => $('#picksheet-comment-div').addClass('hidden')
window.showOrderRefBtn = () => $('#update-ref').removeClass('hidden')
window.showOrderAddressBtn = () => $('#update-address').removeClass('hidden')