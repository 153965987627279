import { Controller } from "stimulus"

export default class extends Controller {

  searchFor() {
    let query = `?query=${event.currentTarget.value}`
    window.location = window.location.pathname + query
  }

  resetCurrent() {
    refreshWithOutParams()
  }

}