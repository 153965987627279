require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

import '@fortawesome/fontawesome-free/js/all'
import './bootstrap_custom.js'
import './valid_product_options.js'
import './turbolinks_load.js'
import './browser_check.js'
import './flash.js'
import './user_agents.js'
import './password_validation.js'
import "controllers"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

window.Rails = require("@rails/ujs")
window.jQuery = $
window.$ = $ 

// if (window.Intl && typeof window.Intl === "object") {
//   window.formatter = new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency: 'USD',
//   })
// }

window.currencyFormat = (num) => {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

window.refreshWithParams = () => {
  window.location = window.location.pathname + window.location.search
}

window.refreshWithOutParams = () => {
  window.location = window.location.pathname
}
  
window.isPresent = (value, transform) => {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}

window.getSelectedText = (sel) => {
  return sel.options[sel.selectedIndex].text
}

window.getSelectedValue = (sel) => {
  return sel.options[sel.selectedIndex].value
}

window.renderGenericModal = (title = null, body = null) => {
  let modalTitle = $('#genericModalLabel')
  let modalBody = $('#genericModalBody')
  modalTitle.text(`${title}`)
  modalBody.text(`${body}`)
  $('#genericModal').modal('toggle')
}

window.getQueryParams = (params, url) => {
  let href = url
  let reg = new RegExp( '[?&]' + params + '=([^&#]*)', 'i' )
  let queryString = reg.exec(href)
  return queryString ? queryString[1] : null
}