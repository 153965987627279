window.fadeOutFlashMsg = () => {
  setTimeout(() => {
    $('[class^="alert"]').fadeOut()
  }, 5000)
}

window.renderGenericFlash = (_class, msg) => {
  $('#msg-board').find('.flash-msg').remove()
  let flash_class = `.alert-${_class}.alert-dismissible.flash-msg`
  $('#msg-board').append(`<div class= 'alert alert-${_class} alert-dismissible flash-msg'></div>`)
  $(flash_class).text(`${msg}`)
  $('.alert-dismissible').append('<button aria-label="Close" class="close" data-dismiss="alert" type="button"><span aria-hidden="true">X</span></button>')
  $(flash_class).show()
  fadeOutFlashMsg()
}