const eightChars      = RegExp(/.{8,}/)
const specialChars    = RegExp(/[\W]/)
const upperCaseChars  = RegExp(/[A-Z]/)
const lowerCaseChars  = RegExp(/[a-z]/)
const digitChars      = RegExp(/[0-9]/)

const red  = "#dc3545"
const orange = "#ed5d09"
const yellow   = "#ffc107"
const blue  = "#17a2b8"
const green  = "#28a745"

document.addEventListener('turbolinks:load', () => {
  let pw = document.getElementById('user-password')
  if (pw) {
    pw.addEventListener('keyup', () => {
      let entropy = entropyLevel(pw.value)
      let strength = strengthLevel(entropy)
      adjustBar(strength)
    })
  }
})

adjustBar = (strength) => {
  let meter = document.getElementById('pw-progress-bar')
  meter.style.width = strength + "%"
  if (strength < 20) {
    meter.innerHTML = ''
  } else if (strength === 20) {
    meter.innerHTML = "Very weak"
    meter.style.backgroundColor = red
  } else if (strength === 40) {
    meter.innerHTML = "Weak"
    meter.style.backgroundColor = orange
  } else if (strength === 60) {
    meter.innerHTML = "Good"
    meter.style.backgroundColor = yellow
  } else if (strength === 80) {
    meter.innerHTML = "Very good"
    meter.style.backgroundColor = green
  }else if (strength == 100) {
    meter.innerHTML = "Strong"
    meter.style.backgroundColor = blue
  }
}

entropyLevel = (pw) => {
  let entropy = 0
  if (hasLowerCaseCharacter(pw)) {
    entropy++
  }
  if (hasUpperCaseCharacter(pw)) {
    entropy++
  }
  if (hasSpecialCharacter(pw)) {
    entropy++
  }
  if (hasDigitCharacter(pw)) {
    entropy++
  }
  if (hasEightCharacters(pw)) {
    entropy++
  }
  return entropy
}

strengthLevel = (entropy) => {
  let strength = 0
  switch (entropy) {
    case 1:
      strength = 20
      break;
    case 2:
      strength = 40
      break;
    case 3:
      strength = 60
      break;
    case 4:
      strength = 80
      break;
    case 5:
      strength = 100
      break;
    }
  return strength
}

validColor = (el) => {
  el.style.color = green
}

invalidColor = (el) => {
  el.style.color = red
}

hasEightCharacters = (pw) => {
  let el = document.getElementById('pw-length')
  if (eightChars.test(pw)) {
    validColor(el)
    return true
  } 
  else {
    invalidColor(el)
    return false
  }
}

hasSpecialCharacter = (pw) => {
  let el = document.getElementById('pw-special')
  if (specialChars.test(pw)) {
    validColor(el)
    return true
  }
  else {
    invalidColor(el)
    return false
  }
}

hasUpperCaseCharacter = (pw) => {
  let el = document.getElementById('pw-upper')
  if (upperCaseChars.test(pw)) {
    validColor(el)
    return true
  }
  else {
    invalidColor(el)
    return false
  }
}

hasLowerCaseCharacter = (pw) => {
  let el = document.getElementById('pw-lower')
  if (lowerCaseChars.test(pw)) {
    validColor(el)
    return true
  }
  else {
    invalidColor(el)
    return false
  }
}

hasDigitCharacter = (pw) => {
  let el = document.getElementById('pw-digit')
  if (digitChars.test(pw)) {
    validColor(el)
    return true
  }
  else {
    invalidColor(el)
    return false
  }
}