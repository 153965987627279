import { Controller } from "stimulus"

export default class extends Controller {

  sortByHeader() {
    window.location = this.buildPathWithParams()
  }

  buildPathWithParams() {
    let url = `${window.location.pathname}?header=${this.data.get('header')}&direction=${this.getDirection()}`
    let query = getQueryParams('query', window.location.search)
    if (query) { url = url + `&query=${query}` }
    return url
  }

  getDirection() {
    if ( this.direction == 'desc') {
      this.direction = 'asc'
    } else if ( this.direction == 'asc' ) {
      this.direction = 'desc'
    }
    return this.direction
  }

  get direction() {
    return this.data.get('direction')
  }

  set direction(param) {
    this.data.set('direction', param)
  }

}